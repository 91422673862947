/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { useLocation, useRouteMatch } from 'react-router';
import styles from './login.module.scss';
import ENVIRONMENTS from '../../../environments';
import { RootState } from '../../../store/create-store';
import { UserService } from '../../../common/services/user/user.service';
import { IReferrerData } from '../../../common/services/user/user-data.interface';
import Welcome from './components/welcome.component';
import MainFeatures from './components/main-features.component';
import IncomeOpportunities from './components/income-opportunity.component';
import Faqs from './components/faqs.component';
import Summary from './components/summary.component';
import Footer from './components/footer.component';
import TopMenuBar from './components/topmenu.component';
import SubscriptionPlan from './components/subscription-plan.component';
import GiftCards from './components/gift-cards.component';
import RegisterBlock from './components/register-block';
import Testimonials from './components/testimonials.component';
import QuickStarterBanner from './components/quick-starter-banner';
import TrendingMesseges from './components/trending-messeges.component';
import Contest from './components/contest.component';
import RaiseMoney from './components/raise-money.component';
import PreLaunch from './components/prelaunch.component';
import { useAppData } from '../../../common/contexts/app-data.context';
import WhyJoin from './components/why-join.component';
// import { CardDemo } from './components/testimonials.component';
import CrowdFunding from './components/crowd-funding.component';
import FreeTrialBanner from './components/free-trial-banner';
import GameCoinsDetails from './components/game-coins-details';
import FreeBeta from './components/free-beta.component';
import FoundingMembership from './components/founding-membership.component';

const userServices = new UserService();

function Login(): JSX.Element {
  const location = useLocation();
  const match = useRouteMatch();

  const [referredBy, setReferredBy] = useState<IReferrerData>();
  const [isLoadingReferrer, setIsLoadingReferrer] = useState(false);

  const redirectToSignUp = () => {
    const redirectUri =
      window.location.origin +
      (location.pathname === '/' ? '' : location.pathname);
    if (location.pathname !== '/')
      window.localStorage.setItem('redirect_uri', redirectUri);
    const signUphURL = `${ENVIRONMENTS.KEYCLOAK_ACCOUNT}/protocol/openid-connect/registrations?client_id=${ENVIRONMENTS.KEYCLOAK_CLIENT_ID}&response_mode=fragment&response_type=code&login=true&redirect_uri=${redirectUri}&from=Signup`;
    window.location.href = signUphURL;
  };

  // const [, setFixedHeader] = useState(false);

  // useEffect(() => {
  //   window.addEventListener('scroll', () => {
  //     if (window.pageYOffset > 64) {
  //       setFixedHeader(true);
  //     } else {
  //       setFixedHeader(false);
  //     }
  //   });
  // }, []);

  const commonState = useSelector((state: RootState) => state.common);
  const { auth, user } = commonState;

  const isLoggedIn = Boolean(auth) && Boolean(user);
  const { params: pathParams }: { params: any } = match;
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const { setServiceAvailable, serviceAvailable } = useAppData();

  const getReferrerUser = async (
    channelId: string = pathParams.referrerChannelId,
    startNow = false,
  ) => {
    try {
      setIsLoadingReferrer(true);
      const referrer = await userServices.visitUserReferralPage(
        channelId,
        queryParams.get('refCode') || undefined,
        startNow,
      );
      setServiceAvailable(
        referrer.serviceAvailabilityStatus !== 'NOT_AVAILABLE',
      );
      setReferredBy(referrer);
    } catch (error) {
      console.error(error);
      setServiceAvailable(serviceAvailable);
    } finally {
      setIsLoadingReferrer(false);
    }
  };

  useEffect(() => {
    if (pathParams.referrerChannelId && !referredBy) {
      localStorage.setItem('referringChannelId', pathParams.referrerChannelId);
      getReferrerUser(pathParams.referrerChannelId);
    }
  }, [pathParams.referrerChannelId]);

  const whyJoinData = [
    {
      icon: '../../../../assets/images/adopter.png',
      title: 'Early Adopter',
      desc: 'Join a community of creatives and thought leaders.',
      gradient: 'linear-gradient(to right, #FFD9A8, #FF8379)',
    },
    {
      icon: '../../../../assets/images/tester.png',
      title: 'Beta Tester',
      desc: 'Get early access before QuickCam is publicly launched.',
      gradient: 'linear-gradient(to right, #F3FFA8, #4AC857)',
    },
    {
      icon: '../../../../assets/images/gameCoins.png',
      title: 'Game Coins',
      desc: 'Earn Game Coins just for signing up.',
      gradient: 'linear-gradient(to right, #A8FFEF, #798EFF)',
    },
    {
      icon: null,
      title: 'Referral Link',
      desc: 'Your own custom QuickCam referral link.',
      gradient: 'linear-gradient(to right, #BFA8FF, #FF79E2)',
    },
    {
      icon: null,
      title: 'More Game Coins',
      desc: 'Earn Game Coins when your Referrals subscribe.',
      gradient: 'linear-gradient(to right, #FFA8C7, #FF7979)',
    },

    {
      icon: null,
      title: 'NFT’s',
      desc: 'Badges “converted” into NFT’s. (crowdfunding goal 4).',
      gradient: 'linear-gradient(to right, #5B94D6, #2FD8FD)',
    },
    {
      icon: null,
      title: 'Shape the Future',
      desc: 'QuickCam AI trained to add fast and slow motion (goal 7).',
      gradient: 'linear-gradient(to right, #0055D5, #CB70EB)',
    },
    {
      icon: null,
      title: 'QuickCam AI Coin',
      desc: 'Convert Game Coins into cryptocurrency (crowdfunding goal 4).',
      gradient: 'linear-gradient(to right, #FFCC48, #D479FF)',
    },
  ];

  return (
    <div className={styles.container}>
      <TopMenuBar referredBy={referredBy} />
      <QuickStarterBanner referredBy={referredBy} />
      <Welcome
        isLoggedIn={isLoggedIn}
        isLoadingReferrer={isLoadingReferrer}
        referredBy={referredBy}
        getReferrerUser={getReferrerUser}
      />
      {/* <RegisterBlock isLoggedIn={isLoggedIn} /> */}
      <MainFeatures />

      <IncomeOpportunities />
      {/* <PreLaunch /> */}
      <WhyJoin data={whyJoinData} />
      <GameCoinsDetails />
      <FoundingMembership />
      {/* <CrowdFunding /> */}
      <Testimonials />

      <FreeBeta />

      {/* <CardDemo /> */}

      {/* <RaiseMoney /> */}

      <SubscriptionPlan />

      {/* <GiftCards referredBy={referredBy} /> */}
      <Faqs />
      {/* <Contest /> */}
      {/* <RegisterBlock isLoggedIn={isLoggedIn} /> */}
      {/* <Summary /> */}
      {/* {!isLoggedIn && (
        <section className={styles.gettingStartedWrapper} id="gettingStarted">
          <p className={styles.gettingStartedHeading}>Getting Started</p>
          <p className={styles.content}>
            Register now for free to get started with QuickCam.
          </p>
          <Button
            color="primary"
            variant="outlined"
            className={styles.actionButton}
            size="large"
            onClick={redirectToSignUp}
          >
            Sign Up
          </Button>
        </section>
      )} */}
      <TrendingMesseges />
      <iframe
        width="100%"
        height="500px"
        src="https://www.youtube.com/embed/HK6y8DAPN_0?si=P3P2bJOMBcTWgceF"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />

      <FreeTrialBanner />
      <Footer referredBy={referredBy} />
    </div>
  );
}

export default Login;
